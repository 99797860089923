// Copyright 2018-2024 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import { Box, createTheme } from '@mui/material'
import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import MqText from '../../components/core/text/MqText'
import { useTheme } from '@emotion/react'
import NotFoundIcon from './../../img/404.svg'

export const Unauthorized: React.FC = () => {
  const theme = createTheme(useTheme())
  const [goBackStatus, setGoBackStatus] = useState(true)
  const [tryAgainStatus, setTryAgainStatus] = useState(true)

  const goBack = () => {
    window.history.back()
  }

  const tryAgain = () => {
    window.location.reload()
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      minHeight='100vh'
      textAlign='center'
    >
      <SVG src={NotFoundIcon} fill={theme.palette.primary.main} />
      <MqText subheading sx={{ marginTop: '2rem', fontSize: '2rem' }}>
        Oops! Page Not Found
      </MqText>
      <MqText activatedSideBar sx={{ marginTop: '0.5rem', fontSize: '1.5rem' }}>
        You are not authorized to view this page. Please contact your administrator.
      </MqText>
    </Box>
  )
}
