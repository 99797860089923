import { Theme } from '@mui/material'
import { THEME_EXTRA } from '../helpers/theme'

export const TABLE_CONTAINER = (theme: Theme) => ({
  border: `1px solid ${THEME_EXTRA.border.table}`,
  borderRadius: theme.spacing(1),
})

export const TABLE_HEADER = {
  backgroundColor: THEME_EXTRA.background.tableHeader,
}

export const TABLE_CELL = (theme: Theme) => ({
  color: theme.palette.common.black,
  borderBottom: `1px solid ${THEME_EXTRA.border.table}`,
})

export const TABLE_HEADER_BORDER = {
  borderBottom: `1px solid ${THEME_EXTRA.border.tableHeader}`,
}

export const DISABLED_BUTTON = { '&.Mui-disabled': { color: THEME_EXTRA.typography.subdued } }

export const CUSTOM_SCROLL = (theme: Theme) => ({
  '::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    height: theme.spacing(0.5),
    width: theme.spacing(0.5),
    cursor: 'pointer',
  },
  '::-webkit-scrollbar-track': {
    background: THEME_EXTRA.background.scrollbar,
    cursor: 'pointer',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
    height: theme.spacing(0.5),
    width: theme.spacing(0.5),
    cursor: 'pointer',
    borderRadius: theme.spacing(1),
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
  },
})
