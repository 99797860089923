// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  Box,
  Container,
  CssBaseline,
  GlobalStyles,
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material'
import { CUSTOM_SCROLL } from '../helpers/commonStyles'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { Provider } from 'react-redux'
import { ReduxRouter, createRouterMiddleware } from '@lagunovsky/redux-react-router'
import { Route, Routes } from 'react-router-dom'
import { Unauthorized } from '../routes/unauthorized-access/Unauthorized'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import { createBrowserHistory } from 'history'
import { theme } from '../helpers/theme'
import { validateToken } from '../helpers'
import ColumnLevel from '../routes/column-level/ColumnLevel'
import Datasets from '../routes/datasets/Datasets'
import Events from '../routes/events/Events'
import Header from './header/Header'
import Jobs from '../routes/jobs/Jobs'
import React, { ReactElement, useEffect, useState } from 'react'
import Sidenav from './sidenav/Sidenav'
import TableLevel from '../routes/table-level/TableLevel'
import Toast from './Toast'
import createRootReducer from '../store/reducers'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../store/sagas'

const sagaMiddleware = createSagaMiddleware({
  onError: (error, _sagaStackIgnored) => {
    console.log('There was an error in the saga', error)
  },
})
const history = createBrowserHistory()
const historyMiddleware = createRouterMiddleware(history)

const store = createStore(
  createRootReducer(history),
  composeWithDevTools(applyMiddleware(sagaMiddleware, historyMiddleware))
)

// sagaMiddleware.run(rootSaga)

const TITLE = 'Data Lineage'

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const token = window.localStorage.getItem('token')
  if (!token || !validateToken(token)) {
    console.log('ProtectedRoute: Unauthorized')
    return <Unauthorized></Unauthorized>
    // return <NotFound></NotFound>
  } else {
    return children
  }
}

const App = (): ReactElement => {
  const urlParameters = new URLSearchParams(window.location.search)
  const encodedToken = urlParameters.get('token')
  const [isValidToken, setIsValidToken] = useState<boolean>(false)
  const [_userId, setUserId] = useState<string>('')
  const [_companyId, setCompanyId] = useState<string>('')
  const [_dataspaceUrl, setDataspaceUrl] = useState<string>('')
  const [themeColor, setThemeColor] = useState<string>(theme.palette.primary.main)

  useEffect(() => {
    window.localStorage.clear()
    if (encodedToken) {
      const decodedTokenObj = validateToken(encodedToken)
      if (decodedTokenObj) {
        console.log('decodedTokenObj:::', decodedTokenObj)
        setUserId(decodedTokenObj.userId)
        setCompanyId(decodedTokenObj.companyId)
        setThemeColor(decodedTokenObj.color)
        setDataspaceUrl(decodedTokenObj.dataspaceUrl)
        sagaMiddleware.run(function* () {
          yield* rootSaga(decodedTokenObj)
        })
        window.localStorage.setItem('token', encodedToken)
        setIsValidToken(true)
      }
    }
  }, [encodedToken])

  const updateTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: { main: themeColor },
    },
  })

  return (
    <Provider store={store}>
      <HelmetProvider>
        <ReduxRouter history={history}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={updateTheme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Helmet>
                  <title>{TITLE}</title>
                </Helmet>
                <CssBaseline />
                <GlobalStyles styles={{ body: CUSTOM_SCROLL(updateTheme) }} />
                <Box>
                  {isValidToken && (
                    <>
                      <Sidenav />
                      <Container
                        maxWidth={'lg'}
                        disableGutters={true}
                        sx={{ maxWidth: '100% !important', padding: '0 2.5rem 0 2.5rem' }}
                      >
                        <Header />
                      </Container>
                    </>
                  )}
                  <Routes>
                    <Route
                      path='/'
                      element={
                        <ProtectedRoute>
                          <Jobs />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={'/datasets'}
                      element={
                        <ProtectedRoute>
                          <Datasets />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={'/events'}
                      element={
                        <ProtectedRoute>
                          <Events />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={'/datasets/column-level/:namespace/:name'}
                      element={
                        <ProtectedRoute>
                          <ColumnLevel />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={'/lineage/:nodeType/:namespace/:name'}
                      element={
                        <ProtectedRoute>
                          <TableLevel />
                        </ProtectedRoute>
                      }
                    />
                  </Routes>
                  <Toast />
                </Box>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ReduxRouter>
      </HelmetProvider>
    </Provider>
  )
}

export default App
