// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import { Box, createTheme } from '@mui/material'
import { JobOrDataset } from '../../types/lineage'
import { Link as RouterLink } from 'react-router-dom'
import { SearchResult } from '../../types/api'
import { THEME_EXTRA } from '../../helpers/theme'
import { encodeNode } from '../../helpers/nodes'
import MqText from '../core/text/MqText'
import React from 'react'
import SVG from 'react-inlinesvg'
import databaseActiveIcon from './../../img/databaseActive.svg'
import jobActiveIcon from './../../img/jobActive.svg'
import moment from 'moment'
import { useTheme } from '@emotion/react'

interface OwnProps {
  searchResult: SearchResult
  search: string
  onClick: (nodeName: string) => void
  selected: boolean
}

const searchResultIcon: { [key in JobOrDataset]: JSX.Element } = {
  JOB: <SVG src={jobActiveIcon} />,
  DATASET: <SVG src={databaseActiveIcon} />,
}

type DkSearchListItemProps = OwnProps

const SearchListItem: React.FC<DkSearchListItemProps> = ({
  searchResult,
  search,
  onClick,
  selected,
}) => {
  const theme = createTheme(useTheme())
  const styleObject = {
    listItem: {
      display: 'block',
      color: 'inherit',
      textDecoration: 'none',
      margin: 0,
      cursor: 'pointer',
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      '&:last-child': {
        borderBottomLeftRadius: '2px',
        borderBottomRightRadius: '2px',
      },
      '&:hover, &.selected': {
        backgroundColor: THEME_EXTRA.background.tableHeader,
      },
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
      },
      '&:nth-pf-type(even)': {
        backgroundColor: theme.palette.common.white,
        '&:hover, &.selected': {
          backgroundColor: THEME_EXTRA.background.tableHeader,
        },
      },
    },
    textOverflow: {
      display: 'inline',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '370px',
    },
  }

  const name = searchResult.name.substring(
    searchResult.name.lastIndexOf('.') + 1,
    searchResult.name.length
  )
  const searchMatchIndex = name.toLowerCase().indexOf(search.toLowerCase())
  return (
    <RouterLink
      style={{
        textDecoration: 'none',
      }}
      onClick={() => onClick(searchResult.name)}
      to={`/lineage/${encodeNode(searchResult.type, searchResult.namespace, searchResult.name)}`}
    >
      <Box className={selected ? 'selected' : ''} sx={styleObject.listItem}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Box display={'flex'} alignItems={'center'}>
            <Box display={'inline'} mr={1}>
              {searchResultIcon[searchResult.type]}
            </Box>
            <Box sx={styleObject.textOverflow}>
              {searchMatchIndex === -1 ? (
                <MqText inline>{name}</MqText>
              ) : (
                <>
                  <MqText inline>{name.substring(0, searchMatchIndex)}</MqText>
                  <MqText inline bold highlight>
                    {name.substring(searchMatchIndex, searchMatchIndex + search.length)}
                  </MqText>
                  <MqText inline>
                    {name.substring(searchMatchIndex + search.length, searchResult.name.length)}
                  </MqText>
                </>
              )}
            </Box>
          </Box>
          <Box>
            <MqText disabled small>
              {moment(searchResult.updatedAt).fromNow()}
            </MqText>
          </Box>
        </Box>
      </Box>
    </RouterLink>
  )
}

export default SearchListItem
