// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import { MetadataObject } from '../../types/api'
import { genericFetchWrapper } from './index'

export const getNamespaces = async (obj: MetadataObject) => {
  const url = `${obj.dataspaceUrl}pub/productionspace-workspaces?userId=${obj.userId}&companyId=${obj.companyId}&page=1&pageSize=1000&sort=asc&workspaceType=Production`
  const workspaceResponse = await genericFetchWrapper(url, { method: 'GET' }, 'fetchNamespaces')
  if (workspaceResponse.success) {
    const workspace = workspaceResponse.data.workspace
    return { namespaces: workspace }
  } else {
    throw new Error('Error getting the workspaces')
  }
}
