import { CenterFocusStrong, CropFree, ZoomIn, ZoomOut } from '@mui/icons-material'
import { THEME_EXTRA } from '../../helpers/theme'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MQTooltip from '../../components/core/tooltip/MQTooltip'
import React from 'react'
import { createTheme } from '@mui/material'
import { useTheme } from '@emotion/react'

interface ZoomControlsProps {
  handleScaleZoom: (inOrOut: 'in' | 'out') => void
  handleResetZoom: () => void
  handleCenterOnNode?: () => void
}

export const ZoomControls = ({
  handleScaleZoom,
  handleResetZoom,
  handleCenterOnNode,
}: ZoomControlsProps) => {
  const theme = createTheme(useTheme())
  return (
    <Box
      display={'flex'}
      border={1}
      borderRadius={1}
      flexDirection={'column'}
      m={1}
      position={'absolute'}
      right={0}
      zIndex={1}
      borderColor={THEME_EXTRA.border.table}
      sx={{ backgroundColor: THEME_EXTRA.background.header }}
    >
      <MQTooltip title={'Zoom in'} placement={'left'}>
        <IconButton
          size='small'
          onClick={() => handleScaleZoom('in')}
          sx={{ color: theme.palette.primary.main }}
        >
          <ZoomIn />
        </IconButton>
      </MQTooltip>
      <MQTooltip title={'Zoom out'} placement={'left'}>
        <IconButton
          size='small'
          onClick={() => handleScaleZoom('out')}
          sx={{ color: theme.palette.primary.main }}
        >
          <ZoomOut />
        </IconButton>
      </MQTooltip>
      <MQTooltip title={'Reset zoom'} placement={'left'}>
        <IconButton
          size={'small'}
          onClick={handleResetZoom}
          sx={{ color: theme.palette.primary.main }}
        >
          <CropFree />
        </IconButton>
      </MQTooltip>
      {handleCenterOnNode && (
        <MQTooltip title={'Center on selected node'} placement={'left'}>
          <IconButton
            size={'small'}
            onClick={handleCenterOnNode}
            sx={{ color: theme.palette.primary.main }}
          >
            <CenterFocusStrong />
          </IconButton>
        </MQTooltip>
      )}
    </Box>
  )
}
