// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import { ArrowBackIosRounded } from '@mui/icons-material'
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Dataset, DatasetVersion } from '../../types/api'
import { THEME_EXTRA } from '../../helpers/theme'
import { alpha, createTheme } from '@mui/material'
import { formatUpdatedAt } from '../../helpers'
import { useTheme } from '@emotion/react'
import DatasetInfo from './DatasetInfo'
import IconButton from '@mui/material/IconButton'
import MqCopy from '../core/copy/MqCopy'
import MqText from '../core/text/MqText'
import React, { FunctionComponent, SetStateAction } from 'react'
import {
  TABLE_CONTAINER,
  TABLE_CELL,
  TABLE_HEADER,
  TABLE_HEADER_BORDER,
} from '../../helpers/commonStyles'

interface DatasetVersionsProps {
  versions: DatasetVersion[]
  dataset: Dataset
}

const DatasetVersions: FunctionComponent<DatasetVersionsProps> = (props) => {
  const { versions, dataset } = props

  const [infoView, setInfoView] = React.useState<DatasetVersion | null>(null)
  const handleClick = (newValue: SetStateAction<DatasetVersion | null>) => {
    setInfoView(newValue)
  }
  const i18next = require('i18next')
  const theme = createTheme(useTheme())

  const styleObject = {
    row: {
      cursor: 'pointer',
      '&:hover': { backgroundColor: theme.palette.action.hover },
    },
    chip: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      border: `1px solid ${THEME_EXTRA.border.tableHeader}`,
    },
  }

  if (versions.length === 0) {
    return null
  }
  if (infoView) {
    return (
      <>
        <Box
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          justifyContent={'space-between'}
          mb={1}
        >
          <Chip
            size={'small'}
            variant={'outlined'}
            label={infoView.version}
            sx={styleObject.chip}
          />
          <IconButton
            onClick={() => handleClick(null)}
            size='small'
            style={{ color: theme.palette.primary.main }}
          >
            <ArrowBackIosRounded fontSize={'small'} />
          </IconButton>
        </Box>
        <DatasetInfo
          dataset={dataset}
          datasetFields={infoView.fields}
          facets={infoView.facets}
          run={infoView.createdByRun}
        />
      </>
    )
  }
  return (
    <TableContainer sx={TABLE_CONTAINER(theme)}>
      <Table size='small'>
        <TableHead sx={TABLE_HEADER}>
          <TableRow>
            <TableCell align='left' sx={TABLE_HEADER_BORDER}>
              <MqText subheading inline>
                {i18next.t('dataset_versions_columns.version')}
              </MqText>
            </TableCell>
            <TableCell align='left' sx={TABLE_HEADER_BORDER}>
              <MqText subheading inline>
                {i18next.t('dataset_versions_columns.created_at')}
              </MqText>
            </TableCell>
            <TableCell align='left' sx={TABLE_HEADER_BORDER}>
              <MqText subheading inline>
                {i18next.t('dataset_versions_columns.fields')}
              </MqText>
            </TableCell>
            <TableCell align='left' sx={TABLE_HEADER_BORDER}>
              <MqText subheading inline>
                {i18next.t('dataset_versions_columns.created_by_run')}
              </MqText>
            </TableCell>
            <TableCell align='left' sx={TABLE_HEADER_BORDER}>
              <MqText subheading inline>
                {i18next.t('dataset_versions_columns.lifecycle_state')}
              </MqText>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {versions.map((version) => {
            return (
              <TableRow
                sx={{
                  cursor: 'pointer',
                  transition: theme.transitions.create(['background-color']),
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.common.white, 0.1),
                  },
                }}
                key={version.createdAt}
                onClick={() => handleClick(version)}
              >
                <TableCell align='left' sx={TABLE_CELL(theme)}>
                  <Box display={'flex'} alignItems={'center'}>
                    {version.version.substring(0, 8)}...
                    <MqCopy string={version.version} />
                  </Box>
                </TableCell>
                <TableCell align='left' sx={TABLE_CELL(theme)}>
                  {formatUpdatedAt(version.createdAt)}
                </TableCell>
                <TableCell align='left' sx={TABLE_CELL(theme)}>
                  {version.fields.length}
                </TableCell>
                <TableCell align='left' sx={TABLE_CELL(theme)}>
                  <Box display={'flex'} alignItems={'center'}>
                    {version.createdByRun ? (
                      <>
                        {version.createdByRun.id.substring(0, 8)}...
                        <MqCopy string={version.createdByRun.id} />
                      </>
                    ) : (
                      'N/A'
                    )}
                  </Box>
                </TableCell>
                <TableCell align='left' sx={TABLE_CELL(theme)}>
                  {version.lifecycleState || 'N/A'}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DatasetVersions
