// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import { useLocation } from 'react-router-dom'
import { createTheme } from '@mui/material'
import { useTheme } from '@emotion/react'
import Box from '@mui/material/Box'
import MqText from '../core/text/MqText'
import NamespaceSelect from '../namespace-select/NamespaceSelect'
import React, { ReactElement } from 'react'
import Search from '../search/Search'

const Header = (): ReactElement => {
  const theme = createTheme(useTheme())

  const location = useLocation()
  let headerTitle = 'Data Lineage'
  if (location.pathname === '/') headerTitle = 'Jobs'
  else if (location.pathname.includes('/datasets')) headerTitle = 'Datasets'
  else if (location.pathname.includes('/events')) headerTitle = 'Events'

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
        // width: `calc(100% - ${DRAWER_WIDTH}px)`,
      }}
    >
      <MqText headerTitle>{headerTitle}</MqText>
      <Box display={'flex'} alignItems={'center'} sx={{ maxWidth: '100% !important' }}>
        <Search />
        <NamespaceSelect />
      </Box>
    </Box>
  )
}

export default Header
