// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import * as Redux from 'redux'
import {
  Chip,
  Container,
  createTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { HEADER_HEIGHT, THEME_EXTRA } from '../../helpers/theme'
import { IState } from '../../store/reducers'
import { Job } from '../../types/api'
import { MqScreenLoad } from '../../components/core/screen-load/MqScreenLoad'
import { Nullable } from '../../types/util/Nullable'
import { Refresh } from '@mui/icons-material'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { encodeNode, runStateColor } from '../../helpers/nodes'
import { fetchJobs, resetJobs } from '../../store/actionCreators'
import { formatUpdatedAt } from '../../helpers'
import { stopWatchDuration } from '../../helpers/time'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import IconButton from '@mui/material/IconButton'
import MQTooltip from '../../components/core/tooltip/MQTooltip'
import MqEmpty from '../../components/core/empty/MqEmpty'
import MqPaging from '../../components/paging/MqPaging'
import MqStatus from '../../components/core/status/MqStatus'
import MqText from '../../components/core/text/MqText'
import NamespaceSelect from '../../components/namespace-select/NamespaceSelect'
import React from 'react'
import {
  DISABLED_BUTTON,
  TABLE_CONTAINER,
  TABLE_CELL,
  TABLE_HEADER,
  TABLE_HEADER_BORDER,
} from '../../helpers/commonStyles'
import { useTheme } from '@emotion/react'

interface StateProps {
  jobs: Job[]
  isJobsInit: boolean
  isJobsLoading: boolean
  selectedNamespace: Nullable<string>
  totalCount: number
}

interface JobsState {
  page: number
}

interface DispatchProps {
  fetchJobs: typeof fetchJobs
  resetJobs: typeof resetJobs
}

type JobsProps = StateProps & DispatchProps

const PAGE_SIZE = 20
const JOB_HEADER_HEIGHT = 64

const Jobs: React.FC<JobsProps> = ({
  jobs,
  totalCount,
  isJobsLoading,
  isJobsInit,
  selectedNamespace,
  fetchJobs,
  resetJobs,
}) => {
  const theme = createTheme(useTheme())

  const defaultState = {
    page: 0,
  }
  const [state, setState] = React.useState<JobsState>(defaultState)

  React.useEffect(() => {
    if (selectedNamespace) {
      fetchJobs(selectedNamespace, PAGE_SIZE, state.page * PAGE_SIZE)
    }
  }, [selectedNamespace, state.page])

  React.useEffect(() => {
    return () => {
      // on unmount
      resetJobs()
    }
  }, [])

  const handleClickPage = (direction: 'prev' | 'next') => {
    const directionPage = direction === 'next' ? state.page + 1 : state.page - 1

    fetchJobs(selectedNamespace || '', PAGE_SIZE, directionPage * PAGE_SIZE)
    // reset page scroll
    window.scrollTo(0, 0)
    setState({ ...state, page: directionPage })
  }

  const i18next = require('i18next')
  return (
    <Container
      maxWidth={'lg'}
      disableGutters
      sx={{ maxWidth: '100% !important', padding: '0 2.5rem 0 2.5rem' }}
    >
      {/* <Box p={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <MqText heading>{i18next.t('jobs_route.heading')}</MqText>
          {!isJobsLoading && (
            <Chip
              size={'small'}
              variant={'outlined'}
              color={'primary'}
              sx={{ marginLeft: 1 }}
              label={totalCount + ' total'}
            ></Chip>
          )}
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          {isJobsLoading && <CircularProgress size={16} />}
          <NamespaceSelect />
          <MQTooltip title={'Refresh'}>
            <IconButton
              sx={{ ml: 2 }}
              color={'primary'}
              size={'small'}
              onClick={() => {
                if (selectedNamespace) {
                  fetchJobs(selectedNamespace, PAGE_SIZE, state.page * PAGE_SIZE)
                }
              }}
            >
              <Refresh fontSize={'small'} />
            </IconButton>
          </MQTooltip>
        </Box>
      </Box> */}
      <MqScreenLoad
        loading={isJobsLoading && !isJobsInit}
        customHeight={`calc(100vh - ${HEADER_HEIGHT}px - ${JOB_HEADER_HEIGHT}px)`}
      >
        <>
          {jobs.length === 0 ? (
            <Box p={2}>
              <MqEmpty title={i18next.t('jobs_route.empty_title')}>
                <MqText dark>{i18next.t('jobs_route.empty_body')}</MqText>
              </MqEmpty>
            </Box>
          ) : (
            <>
              <TableContainer sx={TABLE_CONTAINER(theme)}>
                <Table size='small'>
                  <TableHead sx={TABLE_HEADER}>
                    <TableRow>
                      <TableCell
                        key={i18next.t('jobs_route.name_col')}
                        align='left'
                        sx={TABLE_HEADER_BORDER}
                      >
                        <MqText subheading>{i18next.t('datasets_route.name_col')}</MqText>
                      </TableCell>
                      <TableCell
                        key={i18next.t('jobs_route.namespace_col')}
                        align='left'
                        sx={TABLE_HEADER_BORDER}
                      >
                        <MqText subheading>{i18next.t('datasets_route.namespace_col')}</MqText>
                      </TableCell>
                      <TableCell
                        key={i18next.t('jobs_route.updated_col')}
                        align='left'
                        sx={TABLE_HEADER_BORDER}
                      >
                        <MqText subheading>{i18next.t('datasets_route.updated_col')}</MqText>
                      </TableCell>
                      <TableCell
                        key={i18next.t('jobs_route.latest_run_col')}
                        align='left'
                        sx={TABLE_HEADER_BORDER}
                      >
                        <MqText subheading>{i18next.t('jobs_route.latest_run_col')}</MqText>
                      </TableCell>
                      <TableCell
                        key={i18next.t('jobs_route.latest_run_state_col')}
                        align='left'
                        sx={TABLE_HEADER_BORDER}
                      >
                        <MqText subheading>{i18next.t('jobs_route.latest_run_state_col')}</MqText>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jobs.map((job) => {
                      return (
                        <TableRow key={job.name}>
                          <TableCell align='left' sx={TABLE_CELL(theme)}>
                            <MqText
                              link
                              bold
                              linkTo={`/lineage/${encodeNode('JOB', job.namespace, job.name)}`}
                            >
                              {job.name}
                            </MqText>
                          </TableCell>
                          <TableCell align='left' sx={TABLE_CELL(theme)}>
                            <MqText>{job.namespace}</MqText>
                          </TableCell>
                          <TableCell align='left' sx={TABLE_CELL(theme)}>
                            <MqText>{formatUpdatedAt(job.updatedAt)}</MqText>
                          </TableCell>
                          <TableCell align='left' sx={TABLE_CELL(theme)}>
                            <MqText>
                              {job.latestRun && job.latestRun.durationMs
                                ? stopWatchDuration(job.latestRun.durationMs)
                                : 'N/A'}
                            </MqText>
                          </TableCell>
                          <TableCell
                            key={i18next.t('jobs_route.latest_run_col')}
                            align='left'
                            sx={TABLE_CELL(theme)}
                          >
                            <MqStatus
                              color={job.latestRun && runStateColor(job.latestRun.state || 'NEW')}
                              label={
                                job.latestRun && job.latestRun.state ? job.latestRun.state : 'N/A'
                              }
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <MqPaging
                pageSize={PAGE_SIZE}
                currentPage={state.page}
                totalCount={totalCount}
                incrementPage={() => handleClickPage('next')}
                decrementPage={() => handleClickPage('prev')}
              />
            </>
          )}
        </>
      </MqScreenLoad>
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({
  jobs: state.jobs.result,
  isJobsInit: state.jobs.init,
  isJobsLoading: state.jobs.isLoading,
  selectedNamespace: state.namespaces.selectedNamespace,
  totalCount: state.jobs.totalCount,
})

const mapDispatchToProps = (dispatch: Redux.Dispatch) =>
  bindActionCreators(
    {
      fetchJobs: fetchJobs,
      resetJobs: resetJobs,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Jobs)
