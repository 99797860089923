// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import * as Redux from 'redux'
import { Box, createTheme, FormControl, MenuItem, Select, styled } from '@mui/material'
import { IState } from '../../store/reducers'
import { MqInputBase } from '../core/input-base/MqInputBase'
import { Namespace } from '../../types/api'
import { Nullable } from '../../types/util/Nullable'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { selectNamespace } from '../../store/actionCreators'
import { THEME_EXTRA } from '../../helpers/theme'
import MqText from '../core/text/MqText'
import React from 'react'
import { CUSTOM_SCROLL } from '../../helpers/commonStyles'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'

interface OwnProps {}

interface StateProps {
  namespaces: Namespace[]
  selectedNamespace: Nullable<string>
}

interface DispatchProps {
  selectNamespace: typeof selectNamespace
}

type NamespaceSelectProps = OwnProps & StateProps & DispatchProps

const NamespaceSelect: React.FC<NamespaceSelectProps> = ({
  namespaces,
  selectedNamespace,
  selectNamespace,
}) => {
  const theme = createTheme(useTheme())
  const [open, setOpen] = React.useState(false)
  const i18next = require('i18next')

  const styleObject = {
    formControl: {
      // width: '250px',
      color: theme.palette.common.black,
      minWidth: '140px',
      position: 'relative',
    },
    dropDownButton: {
      cursor: 'pointer',
      '& .MuiSelect-icon': {
        color: theme.palette.common.black,
      },
    },
    box: {
      position: 'absolute',
      left: '8px',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
  }

  const StyledMenuPaper = styled('div')(({ theme }) => CUSTOM_SCROLL(theme))
  const navigate = useNavigate()

  const MenuProps = {
    PaperProps: {
      sx: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${THEME_EXTRA.typography.disabled}`,
        backgroundImage: 'none',
      },
      component: StyledMenuPaper,
    },
  }

  if (selectedNamespace) {
    return (
      <FormControl variant='outlined' sx={styleObject.formControl} onClick={() => setOpen(!open)}>
        <Box sx={styleObject.box}>
          <MqText color={THEME_EXTRA.typography.headerMenuLight} font={'mono'}>
            {i18next.t('namespace_select.prompt')}
          </MqText>
        </Box>
        <Select
          inputProps={{ MenuProps }}
          labelId='namespace-label'
          id='namespace-select'
          value={selectedNamespace}
          onChange={(event) => {
            navigate('/')
            selectNamespace(event.target.value as string)
          }}
          label='Workspace'
          input={<MqInputBase />}
          open={open}
          onClick={() => setOpen(!open)}
          onClose={() => setOpen(false)}
          sx={styleObject.dropDownButton}
        >
          {namespaces.map((namespace) => (
            <MenuItem
              key={namespace.name}
              value={namespace.name}
              sx={{
                backgroundColor:
                  selectedNamespace === namespace.name ? 'rgba(254, 104, 2, 0.16)' : '#FFF',
                '&:hover': {
                  backgroundColor:
                    selectedNamespace === namespace.name
                      ? 'rgba(254, 104, 2, 0.16)'
                      : THEME_EXTRA.background.tableHeader,
                },
              }}
            >
              {namespace.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  } else return null
}

const mapStateToProps = (state: IState) => ({
  namespaces: state.namespaces.result,
  selectedNamespace: state.namespaces.selectedNamespace,
})

const mapDispatchToProps = (dispatch: Redux.Dispatch) =>
  bindActionCreators(
    {
      selectNamespace: selectNamespace,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(NamespaceSelect)
