// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import * as React from 'react'
import { IState } from '../store/reducers'
import { connect } from 'react-redux'
import { createTheme, Snackbar } from '@mui/material'
import { useTheme } from '@emotion/react'
import Box from '@mui/material/Box'
import { THEME_EXTRA } from '../helpers/theme'

interface IProps {
  error?: string
  success?: string
}

const Toast = ({ error, success }: IProps) => {
  const theme = createTheme(useTheme())
  const [snackbarOpen, setSnackbarOpen] = React.useState(true)

  return error || success ? (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={5000}
      style={{ zIndex: theme.zIndex.snackbar }}
      onClose={() => setSnackbarOpen(false)}
      message={error || success}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      ContentProps={{
        style: {
          backgroundColor: success ? THEME_EXTRA.typography.succeeded : theme.palette.error.main,
        },
      }}
    />
    // <Box
    //   sx={Object.assign(
    //     {
    //       position: 'fixed',
    //       bottom: 0,
    //       left: '30%',
    //       borderRadius: theme.shape.borderRadius,
    //       color: theme.palette.common.white,
    //       padding: theme.spacing(2),
    //       maxWidth: '40%',
    //       minWidth: '40%',
    //       textAlign: 'center',
    //       border: `2px dashed ${theme.palette.secondary.main}`,
    //       backgroundColor: theme.palette.background.default,
    //     },
    //     error ? { color: theme.palette.error.main } : { color: theme.palette.primary.main }
    //   )}
    //   className={'shadow animated faster bounceInUp'}
    // >
    //   <p>{error || success}</p>
    // </Box>
  ) : null
}

const mapStateToProps = (state: IState) => ({
  error: state.display.error,
  success: state.display.success,
})

export default connect(mapStateToProps)(Toast)
